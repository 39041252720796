
import { collection, getDocs, DocumentData } from "firebase/firestore";
import { database } from "../FirebaseConfig"
import { SkillCategory } from "../types/SkillCategory";
import { Skill } from "../types/Skill";
import { Project } from "../types/Project";

export const retrieveAll = async (collectionName: string): Promise<Array<SkillCategory | Skill | Project>> => {
	let _sectionItems: Array<SkillCategory | Skill | Project> = [];

	try {
		const snapshot = await getDocs(collection(database, collectionName));
		snapshot.docs.forEach((docData: DocumentData) => {
			_sectionItems.push({
				id: docData.id,
				...docData.data()
			});
		});
	} catch (error) {
		console.error(error);
	}

	return _sectionItems;
};