import './SkillCategory.scss';
import useAppStore from "../../stores/App";
import { SkillCategory as SkillCategoryType } from '../../types/SkillCategory';
import { Skill as SkillType } from '../../types/Skill';
import Skill from '../Skill/Skill';

type Props = {
	skillCategory: SkillCategoryType;
	skillCategoriesToDisplayInSkills: Array<SkillCategoryType>;
}

function SkillCategory({ skillCategory, skillCategoriesToDisplayInSkills }: Props): JSX.Element {
	const skills = useAppStore((state) => state.skills);

	const categoryLabelByCategoryId = (categoryId: string): string => {
		return skillCategoriesToDisplayInSkills.find((category: SkillCategoryType) => category.skillCategoryId === categoryId)?.skillCategoryLabel || "";
	}

	const displayedSkillsByCategoryId = (categoryId: string): Array<SkillType> => {
		return skills.filter((skill: SkillType) => skill.skillCategoryId === categoryId && skill.isDisplayedInSkills);
	};

	return (
		<div className='flex flex-col gap-10 Skill-Category'>
			<span className='text-center Skill-Category-Header'>{categoryLabelByCategoryId(skillCategory.skillCategoryId)}</span>
			<div className='grid gap-5 Skill-Category-Items'>
				{displayedSkillsByCategoryId(skillCategory.skillCategoryId)
					.sort((skill1: SkillType, skill2: SkillType) => skill1.orderByCategoryId - skill2.orderByCategoryId)
					.map((skill: SkillType) => <Skill key={skill.skillId} skill={skill} />)}
			</div>
		</div>
	);
}

export default SkillCategory;
