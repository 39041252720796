import './Header.scss';
import LogoBleu from "../../assets/icons/LogoBleu.svg";
import Photo384 from "../../assets/images/Photo384.webp";
import Photo276 from "../../assets/images/Photo276.webp";
import Logo from '../Logo/Logo';
import ContactBar from '../ContactBar/ContactBar';

/**
 * Steps for using different photos depending on the media :
 * 1) Using the https://squoosh.app/ online tool for the 2), 3) and 4) steps ;
 * 2) Resize the original photo to the desired dimensions in the app (to do for each different version of the photo) ;
 * --> The 1.5 ratio seems to be recommended by Lighthouse: 256x256 rendered photo in app = 384x384 source photo dimensions
 * 3) Reduce the weight of all resized photos ;
 * 4) Convert all photos to WebP format ;
 * 5) Define and use an object containing all info about the photo (see below).
 */

type PhotoSrcSet = {
	id: string;
	src: string;
	type: string;
	media: string;
};

const PHOTO = {
	mainSrc: Photo384,
	srcSet: [
		{
			id: "1",
			src: Photo276,
			type: "image/webp",
			media: "(max-width: 768px)"
		}
	],
	alt: "Damien PROCACCINO"
};

type Props = {
	experienceYearsNumber: number;
}

function Header({ experienceYearsNumber }: Props): JSX.Element {
	const TEXTS = {
		NAME: "Damien PROCACCINO",
		JOB: "Développeur Front-End Senior",
		EXP: `Bac +5 / ${experienceYearsNumber} ans d'expérience`,
		DESC: "Guidé par les bonnes pratiques de développement, l'optimisation des interfaces et l'expérience utilisateur, je suis toujours en quête de projets, par équipe ou en autonomie, pour approfondir mon expertise sur la partie Front-End des applications"
	} as const;

	return (
		<div className='flex flex-col items-center gap-section-items text-center Header'>
			<Logo logoSrc={LogoBleu} />
			<div className='flex flex-col gap-section-subitems Header-Titles'>
				<span className='Header-Titles-Name'>{TEXTS.NAME}</span>
				<span className='Header-Titles-Job'>{TEXTS.JOB}</span>
				<span className='Header-Titles-Experience'>{TEXTS.EXP}</span>
			</div>
			<div className='Header-Photo'>
				<picture>
					{PHOTO.srcSet.map((photoSrcSet: PhotoSrcSet) => (
						<source
							key={photoSrcSet.id}
							srcSet={photoSrcSet.src}
							type={photoSrcSet.type}
							media={photoSrcSet.media}
						/>
					))}
					<img src={PHOTO.mainSrc} alt={PHOTO.alt} />
				</picture>
			</div>
			<p className='m-0 max-w-header-desc Header-Desc'>{TEXTS.DESC}</p>
			<ContactBar />
		</div>
	);
}

export default Header;
