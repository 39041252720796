import './ProjectsNav.scss';
import { useState, useEffect } from 'react';
import { Project } from '../../types/Project';

type Props = {
	projects: Array<Project>;
}

function ProjectsNav({ projects }: Props): JSX.Element {
	const [activeProjectId, setActiveProjectId] = useState<string>("");

	useEffect(() => {
		const projectElementsObserver = new IntersectionObserver((projectElements: Array<IntersectionObserverEntry>) => {
			projectElements.forEach((projectElement: IntersectionObserverEntry) => {
				if (projectElement.isIntersecting) {
					setActiveProjectId(projectElement.target.id);
				}
			});
		}, {
			root: null,
			rootMargin: '-50%',
			threshold: 0
		});

		/**
		 *  rootMargin: '-50%' and threshold: 0 means that the intersection will be detected when
		 *  the first part of the projectElement (i.e. top or bottom edge), so a threshold value of 0, intersects with
		 *  the observation area modified by the rootMargin, here the middle of the page, so a rootMargin value of -50%
		 */

		const projectElements = document.querySelectorAll('.Project.Project-Professional');
		projectElements.forEach((projectElement: Element) => projectElementsObserver.observe(projectElement));

		return () => {
			projectElements.forEach((projectElement: Element) => projectElementsObserver.unobserve(projectElement));
		};
	}, []);

	return (
		<nav className='flex justify-evenly items-center sticky Projects-Nav'>
			{projects
				.sort((project1: Project, project2: Project) => project2.dateFrom.localeCompare(project1.dateFrom))
				.map((project: Project) => (
					<div key={project.id} className={`Button Project ${activeProjectId === project.href ? 'Blue' : ''}`}>
						<a title={`${project.personal?.name || project.professional?.job} - ${project.subject}`} href={`#${project.href}`}>
							<span>{project.num}</span>
						</a>
					</div>
				))
			}
		</nav>
	);
};

export default ProjectsNav;