import './SectionLabel.scss';

type Props = {
	label: string;
}

function SectionLabel({ label }: Props): JSX.Element {
	return <span className="text-center Section-Label">{label}</span>;
}

export default SectionLabel;
